import React, { useState } from 'react';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { AgGridReact } from 'ag-grid-react';
import { GridApi, GridReadyEvent } from 'ag-grid-community';

import StyledScheduledReportFiles from './styles';
import environment from '../../../../../environment';
import DownloadButton from './DownloadButton';
import { dateHourFormatterReport } from '../../../../../utils';
import {
  IScheduledReport,
  IScheduledReportFile
} from '../../../../../interfaces/IScheduledReport';

const ScheduledReportFiles: React.FC<{
  data: IScheduledReport;
}> = ({ data }) => {
  const [generatedFiles, setGeneratedFiles] = useState<IScheduledReportFile[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);

  const getRowData = async (gridApiParam: GridApi) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${environment.apiPath}getScheduledReportFiles/${data.scheduledReportId}`,
        {
          ...environment.params
        }
      );
      setGeneratedFiles(res.data);
      if (res.data.length === 0) {
        gridApiParam?.showNoRowsOverlay();
      }
    } catch (err: AxiosError | any) {
      setGeneratedFiles([]);
      toast.error(err.message);
      gridApiParam?.showNoRowsOverlay();
    } finally {
      setIsLoading(false);
    }
  };

  const onGridReady = (params: GridReadyEvent) => {
    getRowData(params.api);
  };

  const columnDefs = [
    {
      headerName: 'Filename',
      field: 'filename'
    },
    {
      headerName: 'Created Date',
      field: 'createdDate',
      maxWidth: 250,
      valueGetter: (params: any) =>
        params.data && params.data.createdDate
          ? dateHourFormatterReport(params.data.createdDate)
          : ''
    },
    {
      headerName: 'Action',
      field: 'action',
      maxWidth: 100,
      cellRenderer: 'downloadButton'
    }
  ];

  return (
    <StyledScheduledReportFiles>
      <div className='detail__grid ag-theme-balham'>
        <AgGridReact
          rowHeight={30}
          loading={isLoading}
          defaultColDef={{
            sortable: true,
            resizable: true,
            flex: 1
          }}
          enableCellTextSelection
          pagination
          paginationPageSize={5}
          rowData={generatedFiles}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          suppressDragLeaveHidesColumns
          components={{
            downloadButton: DownloadButton
          }}
        />
      </div>
    </StyledScheduledReportFiles>
  );
};

export default ScheduledReportFiles;
