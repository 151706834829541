import React, { useState, SyntheticEvent, useEffect, ChangeEvent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios, { AxiosError } from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { GridApi, GridReadyEvent } from 'ag-grid-community';

import HeroProducts from '../../../assets/heros/hero-products.png';
import HeroBanner from '../../../components/HeroBanner';
import environment from '../../../environment';
import StyledReport from './styles';
import ControlledInput from '../../../components/ControlledInput';
import CustomButton from '../../../components/CustomButton';
import DownloadIcon from '../../../assets/icons/download.svg';
import InventorySearchMenu from './components/InventorySearchMenu/InventorySearchMenu';

const InventoryReporting: React.FC = () => {
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [productCodes, setProductCodes] = useState<string[]>([]);
  const [backorderFlag, setBackorderFlag] = useState<boolean>(false);
  const [stockPot, setStockPot] = useState<string>('');
  const [weeksCover, setWeeksCover] = useState<string>('');
  const [subinventories, setSubinventories] = useState<string[]>([]);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [quickFilter, setQuickFilter] = useState<string>('');

  const getRowData = async () => {
    let rows: any = [];
    let requestsWaiting = 1;
    setIsLoading(true);

    const queryData = async (
      executionId = '',
      nextOffset = '',
      pageSize = ''
    ) => {
      try {
        const res = await axios.get(
          `${
            environment.apiPath
          }getInventoryReportItems?queryExecutionId=${executionId}&nextOffset=${nextOffset}&pageSize=${pageSize}&productCodes=${productCodes.join(
            ','
          )}&stockPot=${stockPot}&backorderFlag=${backorderFlag}&weeksCover=${weeksCover}`,
          {
            ...environment.params
          }
        );

        rows = rows.concat(
          res.data.rows.map((row: any[]) => _.zipObject(res.data.columns, row))
        );

        if (res.data.nextOffsets.length > 0) {
          requestsWaiting = res.data.nextOffsets.length;
          res.data.nextOffsets.forEach((token: any) =>
            queryData(res.data.queryExecutionId, token.offset, token.pageSize)
          );
        } else {
          requestsWaiting -= 1;
        }

        if (requestsWaiting === 0) {
          setRowData(rows);
          gridApi?.hideOverlay();

          if (rows.length === 0) {
            gridApi?.showNoRowsOverlay();
          }
        }
      } catch (err: AxiosError | any) {
        setRowData([]);
        gridApi?.showNoRowsOverlay();
        toast.error(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    await queryData();
  };

  const getSubinventoryList = async () => {
    try {
      const res = await axios.get(
        `${environment.apiPath}getSubinventoriesList`,
        {
          ...environment.params
        }
      );
      setSubinventories(res.data);
    } catch (err: AxiosError | any) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    getSubinventoryList();
  }, []);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    await getRowData();
  };

  const handleDataExport = () => {
    gridApi?.exportDataAsExcel({
      fileName: `InventoryReport_${moment().format('YYYYMMDD_HHmmss')}.xlsx`
    });
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const weeksCoverCellClass = (params: any) => {
    if (params.value === null) {
      return '';
    }
    if (params.value < 1.0) {
      return 'report-cell-red';
    }
    if (params.value < 2.0) {
      return 'report-cell-amber';
    }

    return 'report-cell-green';
  };

  const columnDefs = [
    {
      headerName: 'Item',
      groupId: 'itemGroup',
      children: [
        {
          headerName: 'SKU',
          field: 'sku',
          minWidth: 75,
          enableRowGroup: true,
          cellClass: 'stringType'
        },
        {
          field: 'description',
          minWidth: 300,
          columnGroupShow: 'open'
        }
      ]
    },
    {
      headerName: 'Inventory',
      groupId: 'inventoryGroup',
      children: [
        {
          field: 'subinventory',
          minWidth: 150,
          enableRowGroup: true
        },
        {
          headerName: 'SOH',
          field: 'soh',
          minWidth: 75
        },
        {
          field: 'reservations',
          minWidth: 110
        },
        {
          headerName: 'ATP',
          field: 'atp',
          minWidth: 75
        },
        {
          field: 'backorders',
          minWidth: 100
        },
        {
          headerName: 'Last 7 Days Sales',
          field: 'lastDaysSales',
          minWidth: 150,
          sortable: true
        },
        {
          headerName: 'Average Weekly Sales',
          field: 'averageWeeklySales',
          minWidth: 170,
          sortable: true
        },
        {
          headerName: 'Weeks Cover',
          field: 'weeksCover',
          minWidth: 110,
          sortable: true,
          cellClass: weeksCoverCellClass
        }
      ]
    }
  ];

  return (
    <>
      <HeroBanner title='Inventory Reporting' background={HeroProducts} />
      <InventorySearchMenu
        productCodes={productCodes}
        setProductCodes={setProductCodes}
        stockPot={stockPot}
        setStockPot={setStockPot}
        weeksCover={weeksCover}
        setWeeksCover={setWeeksCover}
        subinventories={subinventories}
        backorderFlag={backorderFlag}
        setBackorderFlag={setBackorderFlag}
        handleSubmit={handleSubmit}
      />
      <StyledReport fixed>
        <div className='report__actions'>
          <ControlledInput
            id='quick-filter'
            placeholder='Filter any column'
            value={quickFilter}
            handleChange={(event: ChangeEvent<HTMLInputElement>) => {
              setQuickFilter(event.target.value);
              gridApi?.setGridOption('quickFilterText', event.target.value);
            }}
            label='Grid filter'
            type='text'
            classes='label--w-30'
          />
          <CustomButton
            type='button'
            classes='btn--w-200-px btn--black'
            title='Export'
            endIcon={<img src={DownloadIcon} alt='Download report' />}
            handleClick={handleDataExport}
          />
        </div>
        <div className='report__grid ag-theme-balham'>
          <AgGridReact
            rowHeight={30}
            loading={isLoading}
            defaultColDef={{
              resizable: true,
              flex: 1,
              minWidth: 150,
              filter: 'agTextColumnFilter'
            }}
            enableCellTextSelection
            pagination
            rowData={rowData}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            rowGroupPanelShow='always'
            suppressDragLeaveHidesColumns
            excelStyles={[
              {
                id: 'stringType',
                dataType: 'String'
              }
            ]}
          />
        </div>
      </StyledReport>
    </>
  );
};

export default InventoryReporting;
