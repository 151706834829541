import React, { useState, useEffect, SyntheticEvent, ChangeEvent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { GridApi, GridReadyEvent } from 'ag-grid-community';

import HeroProducts from '../../../assets/heros/hero-products.png';
import InfoIcon from '../../../assets/icons/info.svg';
import DownloadIcon from '../../../assets/icons/download.svg';
import HeroBanner from '../../../components/HeroBanner';
import StyledReport from './styles';
import environment from '../../../environment';
import ForecastingSearchMenu from './components/ForecastingSearchMenu/ForecastingSearchMenu';
import CustomButton from '../../../components/CustomButton';
import ControlledInput from '../../../components/ControlledInput';
import StarRating from '../components/StarRating';
import ReplenishmentChanges from './components/ReplenishmentChanges';
import EditIcon from '../../../assets/icons/edit.svg';
import CustomTooltip from '../../../components/CustomTooltip';
import {
  IForecasting,
  IReplenishmentChanges
} from '../interfaces/IForecasting';
import { IStoreObjects } from '../interfaces/IStores';

const Forecasting: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [rowData, setRowData] = useState<IForecasting[]>([]);
  const [productCodes, setProductCodes] = useState<string[]>([]);
  const [storeOptions, setStoreOptions] = useState<string[]>([]);
  const [daysCover, setDaysCover] = useState<string>('');
  const [repRequired, setRepRequired] = useState<boolean>(true);
  const [stores, setStores] = useState<string[]>([]);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [storeTier, setStoreTier] = useState<string>('');
  const [storeObjects, setStoreObjects] = useState<IStoreObjects[]>([]);
  const [forecastDates, setForecastDates] = useState<Date[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [exportStatus, setExportStatus] = useState<string>('');
  const [exportDisable, setExportDisable] = useState<boolean>(false);
  const [quickFilter, setQuickFilter] = useState<string>('');
  const [productDescription, setProductDescription] = useState<string>('');
  const [searching, setSearching] = useState<boolean>(false);
  const [replenishmentChanges, setReplenishmentChanges] = useState<
    IReplenishmentChanges[]
  >([]);
  const pageSize = 30000;

  const storeNames: string[] = stores.map((compositeName: string) => {
    const matchingObject: any | undefined = storeObjects.find(
      (obj: any) => obj.compositeName === compositeName
    );

    return matchingObject?.name || -1;
  });

  const getRowData = () => {
    setIsLoading(true);
    let rows: any = [];
    let requestsWaiting = 1;
    const queryData = (nextOffset = '') => {
      setSearching(true);
      axios
        .get(
          `${
            environment.apiPathForecasting
          }getForecastingItems?pageSize=${pageSize}&nextOffset=${nextOffset}&productCodes=${productCodes.join(
            ','
          )}&stores=${storeNames.join(
            ','
          )}&daysCover=${daysCover}&storeTier=${storeTier}&selectedDate=${
            selectedDate
              ? `${selectedDate.getFullYear()}-${
                  selectedDate.getMonth() + 1
                }-${selectedDate.getDate()}`
              : ''
          }&repRequired=${repRequired}&productDescription=${productDescription}`
        )
        .then((res) => {
          rows = rows.concat(
            res.data.rows.map((row: any[]) =>
              _.zipObject(res.data.columns, row)
            )
          );

          if (res.data.nextOffsets.length > 0) {
            requestsWaiting = res.data.nextOffsets.length;
            res.data.nextOffsets.map((token: any) => queryData(token.offset));
          } else {
            requestsWaiting -= 1;
          }

          if (requestsWaiting === 0) {
            setSearching(false);
            setRowData(rows);
            gridApi?.hideOverlay();

            if (rows.length === 0) {
              gridApi?.showNoRowsOverlay();
            }
          }
        })
        .catch((err) => {
          setRowData([]);
          gridApi?.showNoRowsOverlay();
          if (
            (err.response &&
              (err.response.status === 413 || err.response.status === 504)) ||
            err.code === 'ERR_NETWORK'
          ) {
            toast.error('Too many results, please refine your search');
          } else {
            toast.error(err.message);
          }
          setSearching(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    queryData();
  };

  const getStores = () => {
    axios.get(`${environment.apiPathForecasting}getAllStores`).then(
      (res) => {
        const arrayOfNames: string[] = res.data.body.map(
          (obj: any) => obj.compositeName
        );
        setStoreOptions(arrayOfNames.filter((item) => item !== ' - '));
        setStoreObjects(res.data.body);
      },
      (err) => {
        toast.error(err.message);
      }
    );
  };

  const getBulkOrderFile = () => {
    setExportStatus('Generating');
    setExportDisable(true);
    axios
      .get(`${environment.apiPathForecasting}getBulkOrderFile`, {
        responseType: 'json',
        ...environment.params
      })
      .then(
        (res: any) => {
          setExportStatus('Downloading');
          const downloadURL = async () => {
            toast.success('Downloading now...');
            const link = document.createElement('a');
            link.href = res.data.body;
            link.download = 'BulkOrderTemplate.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            await new Promise((r) => setTimeout(r, 2000));
            setExportStatus('Generate');
            setExportDisable(false);
          };
          downloadURL();
        },
        (err) => {
          setExportDisable(false);
          setExportStatus('Generate');
          toast.error(err.response);
        }
      );
  };

  const getForecastDates = () => {
    axios.get(`${environment.apiPathForecasting}getAllForecastDates`).then(
      (res: any) => {
        setForecastDates(res.data.body.map((date: string) => new Date(date)));
        setSelectedDate(new Date(res.data.body.slice(-1)));
      },
      (err) => {
        toast.error(err.message);
      }
    );
  };

  useEffect(() => {
    getStores();
    getForecastDates();
  }, []);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setExportStatus('Generate');
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    setReplenishmentChanges([]);
    getRowData();
  };

  const daysCoverCellClass = (params: any) => {
    if (params.value === undefined) {
      return '';
    }
    if (params.value < 2.0) {
      return 'report-cell-red';
    }
    if (params.value < 4.0) {
      return 'report-cell-amber';
    }

    return 'report-cell-green';
  };

  const lastUpdatedCellClass = (date: Date) => {
    // Sets today and resets the time to 00:00:00
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (moment(date).isSame(today, 'day')) {
      return 'green';
    }
    if (date < today) {
      return 'amber';
    }

    return 'red';
  };

  const renderStars = (params: any) => {
    const cell = (
      <StarRating
        label='top-store-rating'
        params={params}
        readOnly={params.node.group || params.readOnly}
        classes={params.classes}
      />
    );

    if (params.value) {
      return cell;
    }

    return <span />;
  };

  const renderEditTooltip = (params: any) => {
    const cell = (
      <div>
        <CustomTooltip
          title={EditIcon}
          iconlabel='Edit'
          children={params.value}
        />
      </div>
    );

    return cell;
  };

  const replenishmentValidations = (params: any) => {
    const parsedValue = Number(params.newValue);

    if (isNaN(parsedValue) || parsedValue < 0 || parsedValue % 1 !== 0) {
      return false;
    }

    params.data.actualReplenishment = parsedValue;

    return true;
  };

  const columnDefs: any = [
    {
      headerName: 'DSSC',
      field: 'dssc',
      pinned: 'left',
      width: 70
    },
    {
      headerName: 'MULTICHANL',
      field: 'multichannel',
      pinned: 'left',
      width: 80
    },
    {
      headerName: 'SGN-POT',
      field: 'sgnPot',
      pinned: 'left',
      width: 80
    },
    {
      headerName: 'Total Replenishment',
      field: 'totalReplenishment',
      pinned: 'left',
      width: 110
    },
    {
      headerName: 'SKU',
      field: 'productCode',
      pinned: 'left',
      width: 68,
      enableRowGroup: true
    },
    {
      headerName: 'Store Code',
      field: 'storeCode',
      minWidth: 100,
      enableRowGroup: true
    },
    {
      headerName: 'Store Name',
      field: 'storeName',
      minWidth: 150,
      enableRowGroup: true
    },
    {
      headerName: 'Store Tier',
      field: 'tier',
      minWidth: 95,
      cellRenderer: renderStars,
      enableRowGroup: true,
      cellRendererParams: { readOnly: true }
    },
    {
      headerName: 'Description',
      field: 'productName',
      minWidth: 175,
      enableRowGroup: true
    },
    {
      headerName: 'Recommended Replenishment',
      field: 'replenishment',
      minWidth: 100
    },
    {
      headerName: 'Actual Replenishment',
      field: 'actualReplenishment',
      editable: true,
      minWidth: 100,
      cellRenderer: renderEditTooltip,
      valueSetter: replenishmentValidations,
      cellEditor: 'agNumberCellEditor'
    },
    {
      headerName: 'Days Cover',
      field: 'daysCover',
      cellClass: daysCoverCellClass,
      minWidth: 100
    },
    {
      headerName: 'SOH',
      field: 'soh',
      minWidth: 65
    },
    {
      headerName: 'ATP',
      field: 'availableToPromise',
      minWidth: 60
    },
    {
      headerName: 'In Transit',
      field: 'inTransit',
      minWidth: 90
    },
    {
      headerName: 'Weekly Average Sales',
      field: 'averageSales',
      minWidth: 110
    },
    {
      headerName: '7 Days Sales',
      field: 'lastDaysSales',
      minWidth: 110
    }
  ];

  const handleDataExport = () => {
    gridApi?.exportDataAsCsv({
      fileName: `Forecasting_Retail_Replenishment${moment().format(
        'YYYYMMDD_HHmmss'
      )}.csv`
    });
  };

  return (
    <>
      <HeroBanner title='Retail Replenishment' background={HeroProducts} />
      <ForecastingSearchMenu
        productCodes={productCodes}
        setProductCodes={setProductCodes}
        storeOptions={storeOptions}
        stores={stores}
        repRequired={repRequired}
        setRepRequired={setRepRequired}
        setStores={setStores}
        storeTier={storeTier}
        setStoreTier={setStoreTier}
        daysCover={daysCover}
        setDaysCover={setDaysCover}
        handleSubmit={handleSubmit}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        searching={searching}
        setSearching={setSearching}
        forecastDates={forecastDates}
        productDescription={productDescription}
        setProductDescription={setProductDescription}
      />
      <StyledReport fixed>
        <div className='report__actions'>
          <ControlledInput
            id='quick-filter'
            placeholder='Filter any column'
            value={quickFilter}
            handleChange={(event: ChangeEvent<HTMLInputElement>) => {
              setQuickFilter(event.target.value);
              gridApi?.setGridOption('quickFilterText', event.target.value);
            }}
            label='Grid filter'
            type='text'
            classes='label--w-30 label--overflow-inherit'
          />
          <div className='report__actions--divider'>
            <ReplenishmentChanges
              replenishmentChanges={replenishmentChanges}
              setReplenishmentChanges={setReplenishmentChanges}
              gridApi={gridApi}
              getRowData={getRowData}
            />
            <CustomTooltip title={'Generate RMS Report'}>
              <CustomButton
                title={exportStatus}
                classes='btn--w-200-px btn--blue'
                endIcon={<img src={DownloadIcon} alt='Generate RMS Report' />}
                handleClick={getBulkOrderFile}
                disabled={exportDisable}
              />
            </CustomTooltip>
            <CustomTooltip title={'Export current Replenishment Report'}>
              <CustomButton
                type='button'
                title='Export'
                classes='btn--w-200-px btn--black'
                endIcon={
                  <img
                    src={DownloadIcon}
                    alt='Export current Replenishment Report'
                  />
                }
                handleClick={handleDataExport}
              />
            </CustomTooltip>
          </div>
        </div>
        <div className='last-updated--wrapper'>
          {selectedDate && (
            <div
              className={`last-updated ${lastUpdatedCellClass(selectedDate)}`}
            >
              <img src={InfoIcon} alt='Last updated' width='15px' />
              <span>
                <b>Last Updated:</b> {moment(selectedDate).format('YYYY-MM-DD')}
              </span>
            </div>
          )}
        </div>
        <div className='report__grid ag-theme-balham' id='replenishment-table'>
          <AgGridReact
            rowData={rowData}
            rowHeight={30}
            loading={isLoading}
            pagination
            defaultColDef={{
              resizable: true,
              flex: 1,
              sortable: true,
              filter: 'agTextColumnFilter'
            }}
            tooltipShowDelay={0}
            enableCellTextSelection
            onGridReady={onGridReady}
            suppressDragLeaveHidesColumns
            autoGroupColumnDef={{ minWidth: 200, pinned: 'left' }}
            rowGroupPanelShow='always'
            excelStyles={[
              {
                id: 'stringType',
                dataType: 'String'
              }
            ]}
            getRowId={(params) => params.data.id}
            getRowClass={(params) => {
              if (params.data) {
                const rowDataNode = replenishmentChanges.find(
                  (obj) => obj.id === params.data.id
                );
                if (
                  params.data.actualReplenishment ===
                  rowDataNode?.newActualReplenishment
                ) {
                  return 'row--highlight--yellow';
                }
                if (
                  params.data.actualReplenishment !== params.data.replenishment
                ) {
                  return 'row--highlight--green';
                }
              }
            }}
            columnDefs={columnDefs}
          />
        </div>
      </StyledReport>
    </>
  );
};

export default Forecasting;

export type { IReplenishmentChanges };
