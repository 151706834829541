import React, { useEffect, SyntheticEvent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { GridApi } from 'ag-grid-community';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';

import environment from '../../../../../environment';
import StyledTierChange from './styles';
import DisableIcon from '../../../../../assets/icons/disable.svg';
import EnableIcon from '../../../../../assets/icons/enable.svg';
import ReplayIcon from '../../../../../assets/icons/replay.svg';
import ReviewModal from '../../../components/ReviewModal';
import { ITierChanges } from '../../../interfaces/IStores';
import CustomTooltip from '../../../../../components/CustomTooltip';

interface ITierChangesProps {
  tierChanges: ITierChanges[];
  setTierChanges: CallableFunction;
  renderStars: (params: any) => React.JSX.Element;
  gridApi: GridApi | undefined;
}

const TierChanges: React.FC<ITierChangesProps> = ({
  tierChanges,
  setTierChanges,
  renderStars,
  gridApi
}) => {
  const handleTierChange = (params: any) => {
    if (gridApi) {
      const rowDataNode = gridApi.getRowNode(String(params.data.id));
      const prevTierChanges = tierChanges.find(
        (obj) => obj.id === params.data.id
      );
      if (prevTierChanges) {
        setTierChanges(
          tierChanges.map((obj) =>
            obj.id === params.data.id
              ? {
                  ...obj,
                  newTier: params.newValue,
                  undoTier: params.oldValue,
                  undoAction: 'tier change'
                }
              : obj
          )
        );
      } else {
        setTierChanges([
          ...tierChanges,
          {
            id: params.data.id,
            name: params.data.name,
            oldTier: params.oldValue,
            newTier: params.newValue
          }
        ]);
      }
      if (rowDataNode) {
        const transaction = {
          update: [
            {
              ...rowDataNode.data,
              id: params.data.id,
              tier: params.newValue
            }
          ]
        };
        gridApi.applyTransaction(transaction);
      }
    }
  };

  const handleRemoveTierChange = (params: any) => {
    const rowDataNode = params.gridApi.getRowNode(String(params.data.id));
    if (rowDataNode) {
      const transaction = {
        update: [
          {
            ...rowDataNode.data,
            tier: params.data.oldTier
          }
        ]
      };
      params.gridApi.applyTransaction(transaction);

      params.setTierChanges(
        params.tierChanges.map((obj: ITierChanges) =>
          obj.id === params.data.id
            ? {
                ...obj,
                newTier: params.data.oldTier,
                undoTier: params.data.newTier,
                undoAction: 'remove'
              }
            : obj
        )
      );
    }
  };

  const handleUndoTierChange = (params: any) => {
    const rowDataNode = params.gridApi.getRowNode(String(params.data.id));

    if (rowDataNode) {
      const transaction = {
        update: [
          {
            ...rowDataNode.data,
            tier: params.data.undoTier
          }
        ]
      };
      params.gridApi.applyTransaction(transaction);

      params.setTierChanges(
        params.tierChanges.map((obj: ITierChanges) =>
          obj.id === params.data.id
            ? {
                ...obj,
                newTier: params.data.undoTier,
                undoAction: 'none'
              }
            : obj
        )
      );
    }
  };

  const clearTierChanges = () => {
    if (gridApi) {
      tierChanges.forEach((obj) => {
        const rowDataNode = gridApi.getRowNode(String(obj.id));
        if (rowDataNode) {
          const transaction = {
            update: [
              {
                ...rowDataNode.data,
                tier: obj.oldTier
              }
            ]
          };
          gridApi.applyTransaction(transaction);
        }
      });
      setTierChanges([]);
    }

    toast.warning('Tier changes successfully discarded');
  };

  const onModalClose = () => {
    setTierChanges(
      tierChanges.filter((obj: ITierChanges) => obj.newTier !== obj.oldTier)
    );
  };

  const handleSaveTierChange = (params: any) => {
    const body = [
      {
        id: params.data.id,
        tier: params.data.newTier
      }
    ];

    axios
      .post(`${environment.apiPathForecasting}updateStoreTier`, body)
      .then(() => {
        toast.success('Tier value successfully updated');
        const rowDataNode = gridApi?.getRowNode(String(params.data.id));
        if (rowDataNode) {
          const transaction = {
            update: [
              {
                ...rowDataNode.data,
                tier: params.data.newTier
              }
            ]
          };
          gridApi?.applyTransaction(transaction);
        }

        setTierChanges(
          params.tierChanges.map((obj: ITierChanges) =>
            obj.id === params.data.id
              ? {
                  ...obj,
                  oldTier: params.data.newTier,
                  undoTier: params.data.oldTier,
                  undoAction: 'save'
                }
              : obj
          )
        );
      })

      .catch((err) => {
        toast.error(err.message);
      });
  };

  const actionBarRenderer = (params: any) => {
    const cell = (
      <div className='cell__actions'>
        {params.data && params.data.newTier === params.data.oldTier ? (
          <CustomTooltip title={`Undo ${params.data.undoAction}`}>
            <Button
              type='button'
              onClick={() => {
                handleUndoTierChange(params);
              }}
            >
              <img src={ReplayIcon} alt='Undo latest change' />
            </Button>
          </CustomTooltip>
        ) : (
          <>
            <CustomTooltip title='Remove tier changes for this store'>
              <Button
                type='button'
                onClick={() => {
                  handleRemoveTierChange(params);
                }}
              >
                <img src={DisableIcon} alt='Remove tier changes' />
              </Button>
            </CustomTooltip>
            <CustomTooltip title='Save tier change for this store'>
              <Button
                type='button'
                onClick={() => {
                  handleSaveTierChange(params);
                }}
              >
                <img src={EnableIcon} alt='Save tier change' />
              </Button>
            </CustomTooltip>
          </>
        )}
      </div>
    );

    if (!params.node.group) {
      return cell;
    }
  };

  const cellRendererMiddleware = (params: any) => {
    const newParams = { ...params };

    if (params.value) {
      if (params.data) {
        if (params.data.newTier === params.data.oldTier) {
          newParams.classes = 'rating--grey';

          if (params.data.undoAction === 'remove') {
            newParams.readOnly = true;
          }
        }
      }

      return renderStars(newParams);
    }
  };

  const columnDefs: any = [
    {
      headerName: 'Old Tier',
      field: 'oldTier',
      enableRowGroup: true,
      sortable: true,
      minWidth: 95,
      flex: 1,
      cellRenderer: cellRendererMiddleware,
      cellRendererParams: { readOnly: true }
    },
    {
      headerName: 'New Tier',
      field: 'newTier',
      enableRowGroup: true,
      onCellValueChanged: handleTierChange,
      sortable: true,
      minWidth: 95,
      flex: 1,
      cellRenderer: cellRendererMiddleware
    },
    {
      headerName: 'Store Code',
      field: 'id',
      sortable: true,
      flex: 1
    },
    {
      headerName: 'Store Name',
      field: 'name',
      sortable: true,
      flex: 2,
      minWidth: 150
    },
    {
      headerName: 'Actions',
      minwidth: 100,
      flex: 1,
      cellRenderer: actionBarRenderer,
      cellRendererParams: {
        gridApi: gridApi,
        tierChanges: tierChanges,
        setTierChanges: setTierChanges
      }
    }
  ];

  const handleSubmitTierChanges =
    (
      setLoading: (loading: boolean) => void,
      setOpen: (open: boolean) => void
    ) =>
    (event: SyntheticEvent) => {
      event.preventDefault();
      setLoading(true);
      const body = tierChanges
        .filter((obj) => obj.newTier !== obj.oldTier)
        .map((obj) => ({
          id: obj.id,
          tier: obj.newTier
        }));
      if (body.length) {
        axios
          .post(`${environment.apiPathForecasting}updateStoreTier`, body)
          .then(() => {
            setLoading(false);
            setOpen(false);
            toast.success('Tier values successfully updated');
            setTierChanges([]);
          })
          .catch((err) => {
            setLoading(false);
            setOpen(false);
            toast.error(err.message);
          });
      } else {
        setLoading(false);
        setOpen(false);
        setTierChanges([]);
      }
    };

  useEffect(() => {
    if (gridApi) {
      const onCellValueChanged = (params: any) => {
        if (params.colDef.field === 'tier') {
          handleTierChange(params);
        }
      };

      gridApi.addEventListener('cellValueChanged', onCellValueChanged);

      return () => {
        gridApi.removeEventListener('cellValueChanged', onCellValueChanged);
      };
    }
  }, [gridApi, tierChanges]);

  return (
    <ReviewModal
      handleSubmit={handleSubmitTierChanges}
      formTitle='Store Tier Changes'
      formDescription='Please review your changes to be saved or discarded.'
      btnTitle='Review'
      classes='btn--w-200-px btn--light-grey'
      warnings={['Pressing the Clear button will discard all changes.']}
      clearForm={clearTierChanges}
      disabled={tierChanges.length === 0}
      onFormClosed={onModalClose}
    >
      <StyledTierChange fixed>
        <div className='change_report report__grid ag-theme-balham'>
          <AgGridReact
            rowData={tierChanges}
            rowHeight={30}
            pagination
            defaultColDef={{
              resizable: true,
              sortable: true
            }}
            paginationPageSizeSelector={false}
            paginationPageSize={10}
            enableCellTextSelection
            suppressDragLeaveHidesColumns
            getRowClass={(params) => {
              if (params.data) {
                if (params.data.undoAction === 'remove') {
                  return 'row--highlight row--disabled';
                }

                if (params.data.undoAction === 'save') {
                  return 'row--highlight row--saved';
                }
              }
            }}
            getRowId={(params) => params.data.id}
            rowGroupPanelShow='always'
            excelStyles={[
              {
                id: 'numberType',
                dataType: 'Number'
              }
            ]}
            columnDefs={columnDefs}
          />
        </div>
      </StyledTierChange>
    </ReviewModal>
  );
};

export default TierChanges;
