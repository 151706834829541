import React, { useState, useEffect, SyntheticEvent, ChangeEvent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { GridApi, GridReadyEvent } from 'ag-grid-community';

import HeroProducts from '../../../assets/heros/hero-products.png';
import HeroBanner from '../../../components/HeroBanner';
import SearchMenu from './components/SearchMenu';
import environment from '../../../environment';
import { IGenericEntry } from '../../../interfaces/IGenericSection';
import StyledReport from './styles';
import ControlledInput from '../../../components/ControlledInput';
import CustomButton from '../../../components/CustomButton';
import DownloadIcon from '../../../assets/icons/download.svg';
import ArrowRight from '../../../assets/icons/arrow-right.svg';

const Reporting: React.FC = () => {
  const [rowData, setRowData] = useState([]);
  const [productCodes, setProductCodes] = useState<string[]>([]);
  const [productDescription, setProductDescription] = useState<string>('');
  const [stockPot, setStockPot] = useState<string>('');
  const [statusOperators] = useState<IGenericEntry[]>([
    {
      code: 'equals',
      value: 'Equals'
    },
    {
      code: 'lesserEquals',
      value: 'Lesser equals'
    },
    {
      code: 'greaterEquals',
      value: 'Greater equals'
    }
  ]);
  const [statusOperator, setStatusOperator] = useState<IGenericEntry>(
    statusOperators[0]
  );
  const [status, setStatus] = useState<string>('');
  const [createDateOptions] = useState<IGenericEntry[]>([
    {
      code: 'dateRange',
      value: 'Select date range'
    },
    {
      code: 'lastHour',
      value: 'Last hour'
    },
    {
      code: 'lastDay',
      value: 'Last day'
    },
    {
      code: 'lastWeek',
      value: 'Last week'
    },
    {
      code: 'lastMonth',
      value: 'Last month'
    }
  ]);
  const [createDateOption, setCreateDateOption] = useState<IGenericEntry>(
    createDateOptions[0]
  );
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [dateRange, setDateRange] = useState(false);
  const [subinventories, setSubinventories] = useState<string[]>([]);
  const [statuses, setStatuses] = useState<string[]>([]);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [quickFilter, setQuickFilter] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const getRowData = async () => {
    let rows: any = [];
    let requestsWaiting = 1;

    const queue: any[] = [
      {
        executionId: '',
        nextOffset: '',
        pageSize: ''
      }
    ];

    const queryData = async () => {
      if (queue.length === 0) {
        return;
      }

      const params = queue.shift();
      const { executionId, nextOffset, pageSize } = params;

      try {
        setIsLoading(true);
        const res = await axios.get(
          `${
            environment.apiPath
          }getReservationsReportItems?queryExecutionId=${executionId}&nextOffset=${nextOffset}&pageSize=${pageSize}&productCodes=${productCodes.join(
            ','
          )}&descriptions=${productDescription}&stockPot=${stockPot}&status=${status}&statusOperator=${
            statusOperator.code
          }&createDateStart=${
            startDate ? moment.utc(startDate).format('YYYY-MM-DD HH:mm:ss') : ''
          }&createDateEnd=${
            endDate ? moment.utc(endDate).format('YYYY-MM-DD HH:mm:ss') : ''
          }`,
          {
            ...environment.params
          }
        );

        rows = rows.concat(
          res.data.rows.map((row: any[]) => _.zipObject(res.data.columns, row))
        );

        if (res.data.nextOffsets.length > 0) {
          requestsWaiting = res.data.nextOffsets.length;
          res.data.nextOffsets.map((token: any) =>
            queue.push({
              executionId: res.data.queryExecutionId,
              nextOffset: token.offset,
              pageSize: token.pageSize
            })
          );
        } else {
          requestsWaiting -= 1;
        }
        await queryData();

        if (requestsWaiting === 0) {
          setRowData(rows);
          gridApi?.hideOverlay();

          if (rows.length === 0) {
            gridApi?.showNoRowsOverlay();
          }
        }
      } catch (err: AxiosError | any) {
        setRowData([]);
        gridApi?.showNoRowsOverlay();
        if (
          (err.response &&
            (err.response.status === 413 || err.response.status === 504)) ||
          err.code === 'ERR_NETWORK'
        ) {
          toast.error('Too many results, please refine your search');
        } else {
          toast.error(err.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    await queryData();
  };

  const getSubinventoryList = async () => {
    try {
      const res = await axios.get(
        `${environment.apiPath}getSubinventoriesList?type=orderReport`,
        {
          ...environment.params
        }
      );
      setSubinventories(res.data);
    } catch (err: AxiosError | any) {
      toast.error(err.message);
    }
  };

  const getStatusList = async () => {
    try {
      const res = await axios.get(`${environment.apiPath}getItemStatusList`, {
        ...environment.params
      });
      setStatuses(res.data);
    } catch (err: AxiosError | any) {
      toast.error(err.message);
    }
  };

  const dateComparator = (
    filterLocalDateAtMidnight: Date,
    cellValue: string
  ) => {
    const dateAsString = cellValue;
    const dateParts = dateAsString.split('/');
    const cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }

    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }

    return 1;
  };

  useEffect(() => {
    getSubinventoryList();
    getStatusList();
  }, []);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    await getRowData();
  };

  const handleDataExport = () => {
    gridApi?.exportDataAsExcel({
      fileName: `OrdersReport_${moment().format('YYYYMMDD_HHmmss')}.xlsx`
    });
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (createDateOption.value !== 'Select date range') {
      setDateRange(false);
      switch (createDateOption.value) {
        case 'Last hour':
          setStartDate(moment().subtract(1, 'hour').toDate());
          break;
        case 'Last day':
          setStartDate(moment().subtract(1, 'day').toDate());
          break;
        case 'Last week':
          setStartDate(moment().subtract(1, 'week').toDate());
          break;
        case 'Last month':
          setStartDate(moment().subtract(1, 'month').toDate());
          break;

        default:
      }
      setEndDate(new Date());
    }
  }, [createDateOption]);

  useEffect(() => {
    if (dateRange) {
      setCreateDateOption(createDateOptions[0]);
    }
  }, [startDate, endDate]);

  const columnDefs = [
    {
      headerName: 'Item',
      groupId: 'productGroup',
      children: [
        {
          headerName: 'SKU',
          field: 'sku',
          minWidth: 100,
          enableRowGroup: true,
          cellClass: 'stringType'
        },
        {
          headerName: 'Description',
          field: 'description',
          minWidth: 300,
          enableRowGroup: true,
          columnGroupShow: 'open'
        },
        {
          headerName: 'Device Variant',
          field: 'variant',
          minWidth: 250,
          enableRowGroup: true
        },
        {
          headerName: 'Manufacturer',
          field: 'manufacturer',
          minWidth: 150,
          enableRowGroup: true
        },
        {
          headerName: 'Status',
          field: 'status',
          minWidth: 150,
          enableRowGroup: true
        },
        {
          headerName: 'Subinventory',
          field: 'subinventory',
          minWidth: 150,
          enableRowGroup: true
        },
        {
          headerName: 'Warehouse',
          field: 'warehouse',
          minWidth: 100,
          enableRowGroup: true
        },
        {
          headerName: 'Line',
          field: 'orderLine',
          minWidth: 100,
          columnGroupShow: 'open'
        },
        {
          headerName: 'Acq/Ret',
          field: 'acquisition',
          minWidth: 100,
          columnGroupShow: 'open',
          enableRowGroup: true
        },
        {
          headerName: 'Priority',
          field: 'priority',
          minWidth: 100,
          sortable: true,
          columnGroupShow: 'open',
          filter: 'agNumberColumnFilter',
          enableRowGroup: true
        },
        {
          headerName: 'Quantity',
          field: 'quantity',
          minWidth: 100,
          columnGroupShow: 'open',
          filter: 'agNumberColumnFilter',
          type: 'numericColumn'
        },
        {
          headerName: 'Allocated Qty',
          field: 'allocQuantity',
          minWidth: 100,
          columnGroupShow: 'open',
          filter: 'agNumberColumnFilter',
          type: 'numericColumn'
        },
        {
          headerName: 'Backorder Qty',
          field: 'BOQuantity',
          minWidth: 100,
          columnGroupShow: 'open',
          filter: 'agNumberColumnFilter',
          type: 'numericColumn'
        },
        {
          headerName: 'Preorder Qty',
          field: 'POQuantity',
          minWidth: 100,
          columnGroupShow: 'open',
          filter: 'agNumberColumnFilter',
          type: 'numericColumn'
        },
        {
          headerName: 'Picked Qty',
          field: 'pickedQuantity',
          minWidth: 100,
          columnGroupShow: 'open',
          filter: 'agNumberColumnFilter',
          type: 'numericColumn'
        },
        {
          headerName: 'Despatched Qty',
          field: 'despatchedQuantity',
          minWidth: 100,
          columnGroupShow: 'open',
          filter: 'agNumberColumnFilter',
          type: 'numericColumn'
        },
        {
          headerName: 'Required Date',
          field: 'requiredDate',
          minWidth: 120,
          sortable: true,
          filter: 'agDateColumnFilter',
          filterParams: { comparator: dateComparator }
        },
        {
          headerName: 'Predicted Delivery Date',
          field: 'predictedDeliveryDate',
          minWidth: 175,
          sortable: true,
          filter: 'agDateColumnFilter',
          filterParams: { comparator: dateComparator }
        },
        {
          headerName: 'Pick Date',
          field: 'pickDateTime',
          minWidth: 120,
          sortable: true,
          filter: 'agDateColumnFilter',
          filterParams: { comparator: dateComparator },
          valueGetter: (params: any) =>
            params.data && params.data.pickDateTime
              ? moment(params.data.pickDateTime).format('DD/MM/YYYY')
              : ''
        },
        {
          headerName: 'Pick Time',
          field: 'pickDateTime',
          minWidth: 120,
          sortable: true,
          valueGetter: (params: any) =>
            params.data && params.data.pickDateTime
              ? moment(params.data.pickDateTime).format('HH:mm:ss')
              : ''
        },
        {
          headerName: 'Despatch Date',
          field: 'despatchedDate',
          minWidth: 120,
          sortable: true,
          filter: 'agDateColumnFilter',
          filterParams: { comparator: dateComparator }
        },
        {
          headerName: 'Delivery Status',
          field: 'deliveryStatus',
          minWidth: 120,
          sortable: true,
          enableRowGroup: true,
          columnGroupShow: 'open',
          cellClass: 'stringType'
        },
        {
          headerName: 'Serial Number',
          field: 'serialNumber',
          minWidth: 160,
          columnGroupShow: 'open'
        },
        {
          headerName: 'Pick Number',
          field: 'pickNoteNumber',
          minWidth: 110,
          cellClass: 'stringType'
        },
        {
          headerName: 'Consignment Number',
          field: 'consignmentNumber',
          minWidth: 160
        },
        {
          headerName: 'Store ID',
          field: 'storeId',
          minWidth: 90,
          enableRowGroup: true,
          cellClass: 'stringType'
        },
        {
          headerName: 'Store Name',
          field: 'storeName',
          minWidth: 120,
          enableRowGroup: true
        }
      ]
    },
    {
      headerName: 'Order',
      groupId: 'orderGroup',
      children: [
        {
          headerName: 'Order ID',
          field: 'clientOrderNo',
          minWidth: 200,
          enableRowGroup: true,
          cellClass: 'stringType'
        },
        {
          headerName: 'Carisma Order ID',
          field: 'carismaOrder',
          minWidth: 180,
          enableRowGroup: true,
          cellClass: 'stringType'
        },
        {
          headerName: 'Order date',
          field: 'orderDateTime',
          minWidth: 200,
          sortable: true,
          filter: 'agDateColumnFilter',
          filterParams: { comparator: dateComparator },
          valueGetter: (params: any) =>
            params.data && params.data.orderDateTime
              ? moment
                  .utc(params.data.orderDateTime)
                  .local()
                  .format('DD/MM/YYYY')
              : ''
        },
        {
          headerName: 'Order time',
          field: 'orderDateTime',
          sortable: true,
          minWidth: 100,
          valueGetter: (params: any) =>
            params.data && params.data.orderDateTime
              ? moment.utc(params.data.orderDateTime).local().format('HH:mm:ss')
              : ''
        },
        {
          headerName: 'Reservation Date',
          field: 'reservationDateTime',
          minWidth: 120,
          sortable: true,
          columnGroupShow: 'open',
          valueGetter: (params: any) =>
            params.data?.reservationDateTime
              ? moment(params.data.reservationDateTime).format('DD/MM/YYYY')
              : ''
        },
        {
          headerName: 'Reservation Time',
          field: 'reservationDateTime',
          minWidth: 120,
          sortable: true,
          columnGroupShow: 'open',
          valueGetter: (params: any) =>
            params.data?.reservationDateTime
              ? moment(params.data.reservationDateTime).format('HH:mm:ss')
              : ''
        },
        {
          headerName: 'Origin',
          field: 'origin',
          minWidth: 100,
          enableRowGroup: true
        },
        {
          headerName: 'Segment',
          field: 'segment',
          minWidth: 100,
          enableRowGroup: true
        },
        {
          headerName: 'Business Group',
          field: 'businessGroup',
          minWidth: 150,
          enableRowGroup: true
        },
        { headerName: 'Customer Name', field: 'customerName', minWidth: 200 },
        {
          headerName: 'Customer Number',
          field: 'contactNumber',
          minWidth: 140,
          cellClass: 'stringType'
        },
        { headerName: 'Customer Email', field: 'customerEmail', minWidth: 200 },
        {
          headerName: 'Account Number',
          field: 'customerId',
          minWidth: 150,
          enableRowGroup: true,
          cellClass: 'stringType'
        },
        {
          headerName: 'Billing account number',
          field: 'ban',
          minWidth: 100,
          enableRowGroup: true,
          cellClass: 'stringType'
        },
        { headerName: 'Company', field: 'company', minWidth: 200 }
      ]
    },
    {
      headerName: 'Delivery group',
      groupId: 'dgGroup',
      children: [
        { headerName: 'Carrier', field: 'carrier', minWidth: 100 },
        { headerName: 'Service', field: 'service', minWidth: 100 },
        { headerName: 'Group number', field: 'groupNumber', minWidth: 100 },
        {
          headerName: 'Post code',
          field: 'postCode',
          minWidth: 100,
          cellClass: 'stringType'
        }
      ]
    }
  ];

  return (
    <>
      <HeroBanner title='Order Reporting' background={HeroProducts} />
      <SearchMenu
        productDescription={productDescription}
        setProductDescription={setProductDescription}
        statusOperator={statusOperator}
        setStatusOperator={setStatusOperator}
        statusOperators={statusOperators}
        handleSubmit={handleSubmit}
        createDateOption={createDateOption}
        setCreateDateOption={setCreateDateOption}
        createDateOptions={createDateOptions}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        setDateRange={setDateRange}
        stockPot={stockPot}
        setStockPot={setStockPot}
        subinventories={subinventories}
        status={status}
        setStatus={setStatus}
        statuses={statuses}
        productCodes={productCodes}
        setProductCodes={setProductCodes}
        isLoading={isLoading}
      />
      <StyledReport fixed>
        <div className='report__actions'>
          <ControlledInput
            id='quick-filter'
            placeholder='Filter any column'
            value={quickFilter}
            handleChange={(event: ChangeEvent<HTMLInputElement>) => {
              setQuickFilter(event.target.value);
              gridApi?.setGridOption('quickFilterText', event.target.value);
            }}
            label='Grid filter'
            type='text'
            classes='label--w-30'
          />
          <div>
            <CustomButton
              type='button'
              classes='btn--w-200-px btn--black'
              title='Export'
              endIcon={<img src={DownloadIcon} alt='Download report' />}
              handleClick={handleDataExport}
            />
            <CustomButton
              type='button'
              classes='btn--w-200-px btn--light-grey btn--ml-10'
              title='Schedule reports'
              endIcon={
                <img
                  src={ArrowRight}
                  alt='Navigate to scheduled reports page'
                />
              }
              handleClick={() => navigate('/reporting/scheduled-reports')}
            />
          </div>
        </div>
        <div className='report__grid ag-theme-balham'>
          <AgGridReact
            rowHeight={30}
            loading={isLoading}
            defaultColDef={{
              resizable: true,
              flex: 1,
              minWidth: 200,
              filter: 'agTextColumnFilter'
            }}
            enableCellTextSelection
            pagination
            rowData={rowData}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            rowGroupPanelShow='always'
            suppressDragLeaveHidesColumns
            excelStyles={[
              {
                id: 'stringType',
                dataType: 'String'
              }
            ]}
          />
        </div>
      </StyledReport>
    </>
  );
};

export default Reporting;
