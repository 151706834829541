import React, { ChangeEvent, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { GridApi, GridReadyEvent } from 'ag-grid-community';

import HeroProducts from '../../assets/heros/hero-products.png';
import HeroBanner from '../../components/HeroBanner';
import environment from '../../environment';
import StyledConfiguration from './styles';
import ControlledInput from '../../components/ControlledInput';
import CustomButton from '../../components/CustomButton';
import DownloadIcon from '../../assets/icons/download.svg';

const LowValueDeliveryOverride: React.FC = () => {
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [gridApi, setGridApi] = useState<GridApi>();
  const [quickFilter, setQuickFilter] = useState<string>('');

  const getRowData = async (gridApiParam: GridApi) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${environment.apiPath}getConfigItems?page=lowvalueitems`,
        {
          ...environment.params
        }
      );

      setRowData(res.data);

      if (res.data.length === 0) {
        gridApiParam?.showNoRowsOverlay();
      }
    } catch (err: AxiosError | any) {
      setRowData([]);
      toast.error(err.message);
      gridApiParam?.showNoRowsOverlay();
    } finally {
      setIsLoading(false);
    }
  };

  const handleDataExport = () => {
    gridApi?.exportDataAsExcel({
      fileName: 'LowValueDeliveryOverride.xlsx'
    });
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    getRowData(params.api);
  };

  const columnDefs = [
    {
      headerName: 'Product Code',
      field: 'productCode',
      maxWidth: 120,
      cellClass: 'stringType'
    },
    {
      headerName: 'Description',
      field: 'description'
    },
    {
      headerName: 'Delivery Method',
      field: 'deliveryMethod',
      maxWidth: 130,
      enableRowGroup: true
    },
    {
      headerName: 'Service',
      field: 'service',
      maxWidth: 120,
      enableRowGroup: true
    }
  ];

  return (
    <>
      <HeroBanner
        title='Low Value Delivery Override'
        background={HeroProducts}
      />
      <StyledConfiguration fixed>
        <div className='configuration__actions'>
          <ControlledInput
            id='quick-filter'
            placeholder='Filter any column'
            value={quickFilter}
            handleChange={(event: ChangeEvent<HTMLInputElement>) => {
              setQuickFilter(event.target.value);
              gridApi?.setGridOption('quickFilterText', event.target.value);
            }}
            label='Grid filter'
            type='text'
            classes='label--w-30'
          />
          <div>
            <CustomButton
              type='button'
              classes='btn--w-200-px btn--black'
              title='Export'
              endIcon={<img src={DownloadIcon} alt='Download configuration' />}
              handleClick={handleDataExport}
            />
          </div>
        </div>
        <div className='configuration__grid ag-theme-balham'>
          <AgGridReact
            rowHeight={30}
            loading={isLoading}
            defaultColDef={{
              resizable: true,
              flex: 1,
              minWidth: 100,
              filter: 'agTextColumnFilter'
            }}
            enableCellTextSelection
            pagination
            rowData={rowData}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            rowGroupPanelShow='always'
            suppressDragLeaveHidesColumns
            excelStyles={[
              {
                id: 'stringType',
                dataType: 'String'
              }
            ]}
          />
        </div>
      </StyledConfiguration>
    </>
  );
};

export default LowValueDeliveryOverride;
