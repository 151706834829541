import React, { useState, SyntheticEvent, ChangeEvent, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import _ from 'lodash';
import { GridApi, GridReadyEvent } from 'ag-grid-community';

import HeroProducts from '../../../assets/heros/hero-products.png';
import HeroBanner from '../../../components/HeroBanner';
import ReturnsSearchMenu from './components/ReturnsSearchMenu/ReturnsSearchMenu';
import environment from '../../../environment';
import { IGenericEntry } from '../../../interfaces/IGenericSection';
import StyledReport from './styles';
import ControlledInput from '../../../components/ControlledInput';
import CustomButton from '../../../components/CustomButton';
import DownloadIcon from '../../../assets/icons/download.svg';

const ReturnsReporting: React.FC = () => {
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [productCodes, setProductCodes] = useState<string[]>([]);
  const [serialNumber, setSerialNumber] = useState<string>('');
  const [itemType, setItemType] = useState<string>('');
  const [returnTypes] = useState<IGenericEntry[]>([
    {
      code: '',
      value: 'ALL'
    },
    {
      code: 'Pending Return',
      value: 'PENDING RETURN'
    },
    {
      code: 'Cancelled Return',
      value: 'CANCELLED RETURN'
    },
    {
      code: 'Unexpected/Unknown Return',
      value: 'UNEXPECTED'
    },
    {
      code: 'Failed Delivery',
      value: 'FAILED DELIVERY'
    },
    {
      code: 'Customer Return',
      value: 'CUSTOMER RETURN'
    }
  ]);
  const [returnType, setReturnType] = useState<IGenericEntry>(returnTypes[0]);
  const [createDateOptions] = useState<IGenericEntry[]>([
    {
      code: 'dateRange',
      value: 'Select date range'
    },
    {
      code: 'lastHour',
      value: 'Last hour'
    },
    {
      code: 'lastDay',
      value: 'Last day'
    },
    {
      code: 'lastWeek',
      value: 'Last week'
    },
    {
      code: 'lastMonth',
      value: 'Last month'
    }
  ]);
  const [createDateOption, setCreateDateOption] = useState<IGenericEntry>(
    createDateOptions[0]
  );
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [dateRange, setDateRange] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [quickFilter, setQuickFilter] = useState<string>('');

  const getRowData = async () => {
    let rows: any = [];
    let requestsWaiting = 1;

    const queue: any[] = [
      {
        executionId: '',
        nextOffset: '',
        pageSize: ''
      }
    ];

    const queryData = async () => {
      if (queue.length === 0) {
        return;
      }

      const params = queue.shift();
      const { executionId, nextOffset, pageSize } = params;

      try {
        setIsLoading(true);
        const res = await axios.get(
          `${
            environment.apiPath
          }getReturnsReportItems?queryExecutionId=${executionId}&nextOffset=${nextOffset}&pageSize=${pageSize}&productCodes=${productCodes.join(
            ','
          )}&serialNumber=${serialNumber}&itemType=${itemType}&returnType=${
            returnType.code
          }&createDateStart=${
            startDate ? moment.utc(startDate).format('YYYY-MM-DD HH:mm:ss') : ''
          }&createDateEnd=${
            endDate ? moment.utc(endDate).format('YYYY-MM-DD HH:mm:ss') : ''
          }`,
          {
            ...environment.params
          }
        );

        rows = rows.concat(
          res.data.rows.map((row: any[]) => _.zipObject(res.data.columns, row))
        );

        if (res.data.nextOffsets.length > 0) {
          requestsWaiting = res.data.nextOffsets.length;
          res.data.nextOffsets.forEach((token: any) =>
            queue.push({
              executionId: res.data.queryExecutionId,
              nextOffset: token.offset,
              pageSize: token.pageSize
            })
          );
        } else {
          requestsWaiting -= 1;
        }
        await queryData();

        if (requestsWaiting === 0) {
          rows.forEach((row: any) => {
            if (row.orderDate && row.orderTime) {
              const orderDate = moment(row.orderDate, 'DD/MM/YYYY').format(
                'MM/DD/YYYY'
              );
              const date = new Date(`${orderDate} ${row.orderTime} UTC`);
              row.orderDate = moment(date).format('DD/MM/YYYY');
              row.orderTime = moment(date).format('HH:mm:ss');
            }
          });
          setRowData(rows);
          gridApi?.hideOverlay();

          if (rows.length === 0) {
            gridApi?.showNoRowsOverlay();
          }
        }
      } catch (err: AxiosError | any) {
        setRowData([]);
        gridApi?.showNoRowsOverlay();
        toast.error(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    await queryData();
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    await getRowData();
  };

  const handleDataExport = () => {
    gridApi?.exportDataAsExcel({
      fileName: `ReturnOrders_${moment().format('YYYYMMDD_HHmmss')}.xlsx`
    });
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (createDateOption.value !== 'Select date range') {
      setDateRange(false);
      switch (createDateOption.value) {
        case 'Last hour':
          setStartDate(moment().subtract(1, 'hour').toDate());
          break;
        case 'Last day':
          setStartDate(moment().subtract(1, 'day').toDate());
          break;
        case 'Last week':
          setStartDate(moment().subtract(1, 'week').toDate());
          break;
        case 'Last month':
          setStartDate(moment().subtract(1, 'month').toDate());
          break;

        default:
      }
      setEndDate(new Date());
    }
  }, [createDateOption]);

  useEffect(() => {
    if (dateRange) {
      setCreateDateOption(createDateOptions[0]);
    }
  }, [startDate, endDate]);

  const columnDefs = [
    {
      headerName: 'Item',
      groupId: 'itemGroup',
      children: [
        {
          headerName: 'SKU',
          field: 'sku',
          minWidth: 100,
          enableRowGroup: true,
          cellClass: 'stringType'
        },
        {
          headerName: 'Serial Number',
          field: 'serialNumber',
          minWidth: 150,
          cellClass: 'stringType'
        },
        {
          headerName: 'Item Type',
          field: 'producType',
          minWidth: 150,
          enableRowGroup: true
        },
        {
          field: 'condition',
          minWidth: 100,
          enableRowGroup: true
        },
        {
          field: 'description',
          minWidth: 300,
          columnGroupShow: 'open'
        },
        {
          field: 'quantity',
          minWidth: 150
        }
      ]
    },
    {
      headerName: 'Return Order',
      groupId: 'orderGroup',
      children: [
        {
          headerName: 'Order ID',
          field: 'orderId',
          cellClass: 'stringType'
        },
        {
          headerName: 'Return Date',
          field: 'orderDate',
          sortable: true,
          minWidth: 150,
          filter: 'agDateColumnFilter',
          filterParams: {
            comparator: (
              filterLocalDateAtMidnight: Date,
              cellValue: string
            ) => {
              const dateAsString = cellValue;
              const dateParts = dateAsString.split('/');
              const cellDate = new Date(
                Number(dateParts[2]),
                Number(dateParts[1]) - 1,
                Number(dateParts[0])
              );

              if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
              }

              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }

              return 1;
            }
          }
        },
        {
          headerName: 'Return Time',
          field: 'orderTime',
          sortable: true,
          minWidth: 150,
          columnGroupShow: 'open'
        },
        {
          field: 'origin',
          columnGroupShow: 'open'
        },
        {
          field: 'segment',
          columnGroupShow: 'open'
        },
        {
          headerName: 'Return Method',
          field: 'deliveryMethod',
          columnGroupShow: 'open'
        },
        {
          headerName: 'Consignment Number',
          field: 'consignmentNumber',
          columnGroupShow: 'open',
          cellClass: 'stringType'
        },
        {
          headerName: 'Return Type',
          field: 'returnType',
          columnGroupShow: 'open',
          enableRowGroup: true
        },
        {
          headerName: 'Reason code',
          field: 'reasonCode',
          columnGroupShow: 'open',
          enableRowGroup: true
        },
        {
          headerName: 'Reason description',
          field: 'reasonDescription',
          columnGroupShow: 'open'
        },
        {
          headerName: 'Return/Exchange',
          field: 'returnExchange',
          columnGroupShow: 'open'
        },
        {
          headerName: 'Acq/Ret',
          field: 'acquisition',
          columnGroupShow: 'open',
          enableRowGroup: true
        },
        {
          headerName: 'Original Order ID',
          field: 'originalOrderId',
          columnGroupShow: 'open',
          cellClass: 'stringType'
        },
        {
          headerName: 'Original Price (Inc VAT)',
          field: 'originalOrderPrice',
          columnGroupShow: 'open',
          filter: 'agNumberColumnFilter',
          type: 'numericColumn',
          minWidth: 200
        },
        {
          headerName: 'Pre-alert ID',
          field: 'preAlertId',
          columnGroupShow: 'open',
          cellClass: 'stringType'
        },
        {
          headerName: 'Pre-alert Date',
          field: 'preAlertDateTime',
          columnGroupShow: 'open',
          minWidth: 150,
          valueGetter: (params: any) =>
            params.data && params.data.preAlertDateTime
              ? moment(params.data.preAlertDateTime).format('DD/MM/YYYY')
              : ''
        },
        {
          headerName: 'Pre-alert Time',
          field: 'preAlertDateTime',
          columnGroupShow: 'open',
          minWidth: 150,
          valueGetter: (params: any) =>
            params.data && params.data.preAlertDateTime
              ? moment(params.data.preAlertDateTime).format('HH:mm:ss')
              : ''
        }
      ]
    },
    {
      headerName: 'Customer',
      groupId: 'customerGroup',
      children: [
        {
          headerName: 'Account Number',
          field: 'account',
          cellClass: 'stringType'
        },
        {
          headerName: 'Invoice Name',
          field: 'invoiceName',
          columnGroupShow: 'open'
        },
        {
          headerName: 'CTN',
          field: 'ctn',
          columnGroupShow: 'open',
          cellClass: 'stringType'
        }
      ]
    }
  ];

  return (
    <>
      <HeroBanner title='Returns Reporting' background={HeroProducts} />
      <ReturnsSearchMenu
        serialNumber={serialNumber}
        setSerialNumber={setSerialNumber}
        itemType={itemType}
        setItemType={setItemType}
        returnType={returnType}
        setReturnType={setReturnType}
        returnTypes={returnTypes}
        handleSubmit={handleSubmit}
        createDateOption={createDateOption}
        setCreateDateOption={setCreateDateOption}
        createDateOptions={createDateOptions}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        setDateRange={setDateRange}
        productCodes={productCodes}
        setProductCodes={setProductCodes}
      />
      <StyledReport fixed>
        <div className='report__actions'>
          <ControlledInput
            id='quick-filter'
            placeholder='Filter any column'
            value={quickFilter}
            handleChange={(event: ChangeEvent<HTMLInputElement>) => {
              setQuickFilter(event.target.value);
              gridApi?.setGridOption('quickFilterText', event.target.value);
            }}
            label='Grid filter'
            type='text'
            classes='label--w-30'
          />
          <CustomButton
            type='button'
            classes='btn--w-200-px btn--black'
            title='Export'
            endIcon={<img src={DownloadIcon} alt='Download report' />}
            handleClick={handleDataExport}
          />
        </div>
        <div className='report__grid ag-theme-balham'>
          <AgGridReact
            rowHeight={30}
            loading={isLoading}
            defaultColDef={{
              resizable: true,
              flex: 1,
              minWidth: 200,
              filter: 'agTextColumnFilter'
            }}
            enableCellTextSelection
            pagination
            rowData={rowData}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            rowGroupPanelShow='always'
            suppressDragLeaveHidesColumns
            excelStyles={[
              {
                id: 'stringType',
                dataType: 'String'
              }
            ]}
          />
        </div>
      </StyledReport>
    </>
  );
};

export default ReturnsReporting;
