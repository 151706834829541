import React, { ChangeEvent, SyntheticEvent, useState } from 'react';
import { Container, MenuItem, Select } from '@mui/material';

import StyledSearchMenu from './styles';
import CustomButton from '../../../../../components/CustomButton';
import Label from '../../../../../components/Label';
import StyledFormControl from '../../../../../components/ControlledSelect/styles';
import AutoCompleteSearch from '../../../../../components/AutoCompleteSearch/AutoCompleteSearch';
import ProductCodeSearch from '../../../components/ProductCodeSearch/ProductCodeSearch';
import CustomSwitch from '../../../../../components/CustomSwitch';
import ControlledInput from '../../../../../components/ControlledInput';
import SkuGroupSearch from '../../../../../components/SkuGroupSearch/SkuGroupSearch';

interface ISearchMenuProps {
  productCodes: string[];
  setProductCodes: CallableFunction;
  storeOptions: string[];
  stores: string[];
  setStores: CallableFunction;
  daysCover: string;
  setDaysCover: CallableFunction;
  setStoreTier: CallableFunction;
  repRequired: boolean;
  setRepRequired: CallableFunction;
  storeTier: string;
  forecastDates: Date[];
  selectedDate: Date | undefined;
  setSelectedDate: CallableFunction;
  searching: boolean;
  setSearching: CallableFunction;
  productDescription: string;
  setProductDescription: CallableFunction;
  handleSubmit: (event: SyntheticEvent) => void;
}

const ForecastingSearchMenu: React.FC<ISearchMenuProps> = ({
  productCodes,
  setProductCodes,
  storeOptions,
  stores,
  setStores,
  daysCover,
  setDaysCover,
  repRequired,
  setRepRequired,
  storeTier,
  setStoreTier,
  selectedDate,
  searching,
  productDescription,
  setProductDescription,
  handleSubmit
}) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [skuGroupNames, setSkuGroupNames] = useState<string[]>([]);
  const daysCoverFilter = [
    { name: 'All', value: '' },
    { name: '≤ 1', value: '1' },
    { name: '≤ 2', value: '2' },
    { name: '≤ 3', value: '3' },
    { name: '≤ 4', value: '4' },
    { name: '≤ 5', value: '5' },
    { name: '≤ 6', value: '6' },
    { name: '≤ 7', value: '7' }
  ];

  const topStoreOptions = [
    { name: 'All', value: '' },
    { name: '★', value: '1' },
    { name: '★★', value: '2' },
    { name: '★★★', value: '3' }
  ];

  const clearSearchOptions = () => {
    setDaysCover('');
    setProductCodes([]);
    setStores([]);
    setStoreTier('');
    setRepRequired(true);
    setProductDescription('');
    setErrorMessage('');
    setSkuGroupNames([]);
  };

  return (
    <StyledSearchMenu>
      <Container fixed>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>
          <br />
          <div className='search-menu-row'>
            <ProductCodeSearch
              productCodes={productCodes}
              setProductCodes={setProductCodes}
              style={{ width: '40%' }}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
            />
            <SkuGroupSearch
              productCodes={productCodes}
              setProductCodes={setProductCodes}
              skuGroupNames={skuGroupNames}
              setSkuGroupNames={setSkuGroupNames}
              style={{ width: '40%' }}
              classes=''
            />
            <ControlledInput
              id='product-description'
              placeholder='Type item description here'
              value={productDescription}
              handleChange={(event: ChangeEvent<HTMLInputElement>) =>
                setProductDescription(event.target.value)
              }
              label='Item description'
              type='text'
              classes='label--w-30 label--h-77 label--overflow-inherit'
            />
          </div>
          <hr />
          <div className='search-menu-row inline-search-items'>
            <AutoCompleteSearch
              id='store-search'
              label='Store'
              options={storeOptions}
              searchTerms={stores}
              setSearchTerms={setStores}
              placeholder='Select stores here'
              style={{ width: '35%' }}
            />
            <Label
              id='top-30-stores'
              control={
                <StyledFormControl variant={'outlined' as any}>
                  <Select
                    data-testid='select-testid'
                    displayEmpty
                    value={storeTier}
                    onChange={(event: any) => setStoreTier(event.target.value)}
                  >
                    <MenuItem key='placeholder' disabled>
                      Select store tier
                    </MenuItem>
                    {topStoreOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              }
              label='Store tier'
              classes='label--w-20'
            />
          </div>
          <hr />
          <div className='search-menu-row'>
            <Label
              id='days-cover'
              control={
                <StyledFormControl variant={'outlined' as any}>
                  <Select
                    data-testid='select-testid'
                    displayEmpty
                    value={daysCover}
                    onChange={(event: any) => setDaysCover(event.target.value)}
                  >
                    <MenuItem key='placeholder' value='' disabled>
                      Select days cover
                    </MenuItem>
                    {daysCoverFilter.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              }
              label='Days cover'
              classes='label--w-20'
            />
            <StyledFormControl variant={'outlined' as any}>
              <CustomSwitch
                checked={repRequired}
                handleChange={(event: any) =>
                  setRepRequired(event.target.checked)
                }
                onLabel='Replenishment required'
                offLabel='All products'
              />
            </StyledFormControl>
          </div>
          <hr />
          <div className='search-menu-row'>
            <CustomButton
              type='button'
              classes='btn--w-200-px btn--light-grey clear-btn'
              title='Clear'
              handleClick={() => clearSearchOptions()}
            />
            <CustomButton
              type='submit'
              classes='btn--w-200-px'
              title='Search'
              disabled={!selectedDate || searching}
            />
          </div>
        </form>
      </Container>
    </StyledSearchMenu>
  );
};

export default ForecastingSearchMenu;
