import React, { useState } from 'react';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { AgGridReact } from 'ag-grid-react';
import { GridApi, GridReadyEvent } from 'ag-grid-community';

import StyledSubProducts from './styles';
import environment from '../../../../../environment';
import ManageProductsModal from '../ManageProductsModal';
import {
  ISkuGroup,
  ISkuGroupProduct
} from '../../../../../interfaces/ISkuGroup';

const SubProducts: React.FC<{
  setRowData: (data: ISkuGroup[]) => void;
  rowData: ISkuGroup[];
  data: ISkuGroup;
}> = ({ setRowData, rowData, data }) => {
  const { id, skuGroup } = data;
  const [rowProductsData, setProductsRowData] = useState<ISkuGroupProduct[]>(
    []
  );
  const [gridApi, setGridApi] = useState<GridApi>();
  const [isLoading, setIsLoading] = useState(false);

  const getRowData = async (gridApiParam: GridApi) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${environment.apiPath}skuGroup/${id}`, {
        ...environment.params
      });
      setProductsRowData(res.data);
      if (res.data.length === 0) {
        gridApiParam?.showNoRowsOverlay();
      }
    } catch (err: AxiosError | any) {
      setProductsRowData([]);
      toast.error(err.message);
      gridApiParam?.showNoRowsOverlay();
    } finally {
      setIsLoading(false);
    }
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    getRowData(params.api);
  };

  const columnDefs = [
    { headerName: 'SKU', field: 'productCode', maxWidth: 120 },
    { headerName: 'Description', field: 'description' }
  ];

  return (
    <StyledSubProducts>
      <ManageProductsModal
        id={id}
        skuGroup={skuGroup}
        rowProductsData={rowProductsData}
        setProductsRowData={setProductsRowData}
        setRowData={setRowData}
        rowData={rowData}
        gridApi={gridApi}
        gridLoading={isLoading}
      />
      <div className='detail__grid ag-theme-balham'>
        <AgGridReact
          rowHeight={30}
          loading={isLoading}
          defaultColDef={{
            resizable: true,
            flex: 1,
            minWidth: 100,
            filter: 'agTextColumnFilter'
          }}
          enableCellTextSelection
          pagination
          rowData={rowProductsData}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          rowGroupPanelShow='always'
          suppressDragLeaveHidesColumns
        />
      </div>
    </StyledSubProducts>
  );
};

export default SubProducts;
