import React, { ChangeEvent, useState } from 'react';
import axios, { AxiosError } from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { AgGridReact } from 'ag-grid-react';
import { GridApi, GridReadyEvent } from 'ag-grid-community';

import HeroBanner from '../../../components/HeroBanner';
import HeroProducts from '../../../assets/heros/hero-products.png';
import StyledConfiguration from './styles';
import ControlledInput from '../../../components/ControlledInput';
import environment from '../../../environment';
import ControlButtons from './components/ControlButtons/ControlButtons';
import AddSkuGroupModal from './components/AddSkuGroupModal';
import SubProducts from './components/SubProducts';
import { ISkuGroup } from '../../../interfaces/ISkuGroup';
import { IUserPermissions } from '../../../interfaces/IAuthState';

const SkuGroups: React.FC<{ permissions: IUserPermissions }> = ({
  permissions
}) => {
  const [quickFilter, setQuickFilter] = useState<string>('');
  const [gridApi, setGridApi] = useState<GridApi>();
  const [rowData, setRowData] = useState<ISkuGroup[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getRowData = async (gridApiParam: GridApi) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${environment.apiPath}skuGroup`, {
        ...environment.params
      });
      setRowData(res.data);
      if (res.data.length === 0) {
        gridApiParam?.showNoRowsOverlay();
      }
    } catch (err: AxiosError | any) {
      setRowData([]);
      toast.error(err.message);
      gridApiParam?.showNoRowsOverlay();
    } finally {
      setIsLoading(false);
    }
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    getRowData(params.api);
  };

  const columnDefs = [
    {
      headerName: 'SKU Group',
      field: 'skuGroup',
      cellRenderer: 'agGroupCellRenderer'
    },
    {
      headerName: 'Created By',
      field: 'createdBy'
    },
    {
      headerName: 'Created At',
      field: 'createdDate',
      valueGetter: (params: any) =>
        params.data && params.data.createdDate
          ? moment(params.data.createdDate).format('DD/MM/YYYY HH:mm')
          : ''
    },
    {
      headerName: 'Last Updated By',
      field: 'lastUpdateBy'
    },
    {
      headerName: 'Last Updated At',
      field: 'lastUpdateDate',
      valueGetter: (params: any) =>
        params.data && params.data.lastUpdateDate
          ? moment(params.data.lastUpdateDate).format('DD/MM/YYYY HH:mm')
          : ''
    },
    {
      field: 'actions',
      cellRenderer: 'controlButtons',
      cellRendererParams: {
        permissions: permissions,
        setRowData: setRowData,
        rowData: rowData
      }
    }
  ];

  return (
    <>
      <HeroBanner title='SKU Groups' background={HeroProducts} />
      <StyledConfiguration fixed>
        <div className='reporting__actions'>
          <ControlledInput
            id='quick-filter'
            placeholder='Filter any column'
            value={quickFilter}
            handleChange={(event: ChangeEvent<HTMLInputElement>) => {
              setQuickFilter(event.target.value);
              gridApi?.setGridOption('quickFilterText', event.target.value);
            }}
            label='Grid filter'
            type='text'
            classes='label--w-30'
          />
          <AddSkuGroupModal
            rowData={rowData}
            setRowData={setRowData}
            gridApi={gridApi}
            gridLoading={isLoading}
          />
        </div>
        <div className='reporting__grid ag-theme-balham'>
          <AgGridReact
            masterDetail
            detailRowHeight={410}
            components={{
              detailCellRenderer: SubProducts,
              controlButtons: ControlButtons
            }}
            rowHeight={30}
            loading={isLoading}
            defaultColDef={{
              resizable: true,
              flex: 1,
              minWidth: 100,
              filter: 'agTextColumnFilter'
            }}
            enableCellTextSelection
            pagination
            rowData={rowData}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            detailCellRenderer='detailCellRenderer'
            detailCellRendererParams={{ rowData, setRowData }}
            rowGroupPanelShow='always'
            suppressDragLeaveHidesColumns
          />
        </div>
      </StyledConfiguration>
    </>
  );
};

export default SkuGroups;
