import React, { ChangeEvent, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { GridApi, GridReadyEvent } from 'ag-grid-community';

import HeroProducts from '../../assets/heros/hero-products.png';
import HeroBanner from '../../components/HeroBanner';
import environment from '../../environment';
import StyledConfiguration from './styles';
import ControlledInput from '../../components/ControlledInput';
import CustomButton from '../../components/CustomButton';
import DownloadIcon from '../../assets/icons/download.svg';

const HierarchyAddOns: React.FC = () => {
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [gridApi, setGridApi] = useState<GridApi>();
  const [quickFilter, setQuickFilter] = useState<string>('');

  const getRowData = async (gridApiParam: GridApi) => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `${environment.apiPath}getConfigItems?page=hierarchyaddon`,
        {
          ...environment.params
        }
      );

      setRowData(res.data);

      if (res.data.length === 0) {
        gridApiParam?.showNoRowsOverlay();
      }
    } catch (err: AxiosError | any) {
      setRowData([]);
      toast.error(err.message);
      gridApiParam?.showNoRowsOverlay();
    } finally {
      setIsLoading(false);
    }
  };

  const handleDataExport = () => {
    gridApi?.exportDataAsExcel({
      fileName: 'HierarchyAddons.xlsx'
    });
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    getRowData(params.api);
  };

  const columnDef = [
    { field: 'hierarchy', enableRowGroup: true, minWidth: 125 },
    { field: 'hierarchyName' },
    { field: 'origin', minWidth: 75 },
    { field: 'segment' },
    {
      field: 'addOnProduct',
      headerName: 'Add-on SKU',
      enableRowGroup: true,
      minWidth: 125,
      cellClass: 'stringType'
    },
    { field: 'addOnName', headerName: 'Add-on Name', minWidth: 200 },
    { field: 'warehouse' },
    { field: 'quantity' },
    { field: 'acqRet', headerName: 'Acq/Ret' },
    {
      field: 'addOnNoStock',
      headerName: 'Add if out of stock',
      valueGetter: (params: any) =>
        params.data ? (params.data.addOnNoStock ? 'Y' : 'N') : '',
      minWidth: 125
    },
    {
      field: 'solitary',
      valueGetter: (params: any) =>
        params.data ? (params.data.solitary ? 'Y' : 'N') : ''
    },
    {
      field: 'active',
      headerName: 'Status',
      valueGetter: (params: any) =>
        params.data ? (params.data.active ? 'Active' : 'Inactive') : '',
      enableRowGroup: true,
      minWidth: 75
    }
  ];

  return (
    <>
      <HeroBanner title='Hierarchy Add-ons' background={HeroProducts} />
      <StyledConfiguration fixed>
        <div className='configuration__actions'>
          <ControlledInput
            id='quick-filter'
            placeholder='Filter any column'
            value={quickFilter}
            handleChange={(event: ChangeEvent<HTMLInputElement>) => {
              setQuickFilter(event.target.value);
              gridApi?.setGridOption('quickFilterText', event.target.value);
            }}
            label='Grid filter'
            type='text'
            classes='label--w-30'
          />
          <div>
            <CustomButton
              type='button'
              classes='btn--w-200-px btn--black'
              title='Export'
              endIcon={<img src={DownloadIcon} alt='Download configuration' />}
              handleClick={handleDataExport}
            />
          </div>
        </div>
        <div className='configuration__grid ag-theme-balham'>
          <AgGridReact
            rowHeight={30}
            defaultColDef={{
              resizable: true,
              flex: 1,
              minWidth: 100,
              filter: 'agTextColumnFilter'
            }}
            loading={isLoading}
            enableCellTextSelection
            pagination
            rowData={rowData}
            columnDefs={columnDef}
            onGridReady={onGridReady}
            rowGroupPanelShow='always'
            suppressDragLeaveHidesColumns
            excelStyles={[
              {
                id: 'stringType',
                dataType: 'String'
              }
            ]}
          />
        </div>
      </StyledConfiguration>
    </>
  );
};

export default HierarchyAddOns;
