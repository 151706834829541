import React, { useState, useEffect, SyntheticEvent, ChangeEvent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import _ from 'lodash';
import { GridApi, GridReadyEvent } from 'ag-grid-community';

import HeroProducts from '../../../assets/heros/hero-products.png';
import HeroBanner from '../../../components/HeroBanner';
import StockAlignmentSearchMenu from './components/StockAlignmentSearchMenu/StockAlignmentSearchMenu';
import environment from '../../../environment';
import StyledReport from './styles';
import ControlledInput from '../../../components/ControlledInput';
import CustomButton from '../../../components/CustomButton';
import DownloadIcon from '../../../assets/icons/download.svg';

const StockAlignmentReporting: React.FC = () => {
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [productCodes, setProductCodes] = useState<string[]>([]);
  const [stockPot, setStockPot] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [snapshotDate, setSnapshotDate] = useState<Date | null>(null);
  const [subinventories, setSubinventories] = useState<string[]>([]);
  const [statuses, setStatuses] = useState<string[]>([]);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [quickFilter, setQuickFilter] = useState<string>('');

  const getRowData = async () => {
    let rows: any = [];

    const queryData = async () => {
      try {
        setIsLoading(true);
        const res = await axios.get(
          `${
            environment.apiPath
          }getInventoryAlignmentReport?sku=${productCodes.join(
            ','
          )}&subinventory=${stockPot}&status=${status}&date=${
            snapshotDate
              ? moment.utc(snapshotDate).format('YYYY-MM-DD HH:mm')
              : ''
          }`,
          {
            ...environment.params
          }
        );

        rows = rows.concat(
          res.data.rows.map((row: any[]) => _.zipObject(res.data.columns, row))
        );
        setRowData(rows);
        gridApi?.hideOverlay();

        if (res.data.rows.length === 0) {
          gridApi?.showNoRowsOverlay();
        }
      } catch (err: AxiosError | any) {
        setRowData([]);
        gridApi?.showNoRowsOverlay();
        toast.error(err.response.data.error);
      } finally {
        setIsLoading(false);
      }
    };

    await queryData();
  };

  const getSubinventoryList = async () => {
    try {
      const res = await axios.get(
        `${environment.apiPath}getSubinventoriesList?type=inventorySnapshot`,
        {
          ...environment.params
        }
      );
      setSubinventories(res.data);
    } catch (err: AxiosError | any) {
      toast.error(err.message);
    }
  };

  const getStatusList = async () => {
    try {
      const res = await axios.get(
        `${environment.apiPath}getStockAlignmentStatusList`,
        {
          ...environment.params
        }
      );
      setStatuses(res.data);
    } catch (err: AxiosError | any) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    getSubinventoryList();
    getStatusList();
  }, []);

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    getRowData();
  };

  const handleDataExport = () => {
    gridApi?.exportDataAsExcel({
      fileName: `StockAlignmentReport_${moment().format(
        'YYYYMMDD_HHmmss'
      )}.xlsx`
    });
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
  };

  const columnDefs = [
    {
      headerName: 'SKU',
      field: 'sku',
      maxWidth: 80,
      enableRowGroup: true,
      cellClass: 'stringType'
    },
    {
      field: 'description',
      minWidth: 350
    },
    {
      field: 'sellable',
      maxWidth: 90,
      valueGetter: (params: any) =>
        params.data ? (params.data.sellable ? 'Y' : 'N') : ''
    },
    {
      field: 'subinventory',
      minWidth: 150,
      enableRowGroup: true
    },
    {
      field: 'status',
      minWidth: 100,
      enableRowGroup: true,
      sortable: true
    },
    {
      headerName: 'Cavendish SOH',
      field: 'cavendishStockOnHand',
      maxWidth: 130,
      sortable: true
    },
    {
      headerName: 'RMS SOH',
      field: 'rmsStockOnHand',
      maxWidth: 100,
      sortable: true
    },
    {
      headerName: 'Difference',
      field: 'difference',
      maxWidth: 100,
      sortable: true
    },
    {
      field: 'importDate',
      maxWidth: 140,
      sortable: true,
      filter: 'agDateColumnFilter'
    }
  ];

  return (
    <>
      <HeroBanner title='Stock Alignment Reporting' background={HeroProducts} />
      <StockAlignmentSearchMenu
        handleSubmit={handleSubmit}
        snapshotDate={snapshotDate}
        setSnapshotDate={setSnapshotDate}
        stockPot={stockPot}
        setStockPot={setStockPot}
        subinventories={subinventories}
        status={status}
        setStatus={setStatus}
        statuses={statuses}
        productCodes={productCodes}
        setProductCodes={setProductCodes}
      />
      <StyledReport fixed>
        <div className='report__actions'>
          <ControlledInput
            id='quick-filter'
            placeholder='Filter any column'
            value={quickFilter}
            handleChange={(event: ChangeEvent<HTMLInputElement>) => {
              setQuickFilter(event.target.value);
              gridApi?.setGridOption('quickFilterText', event.target.value);
            }}
            label='Grid filter'
            type='text'
            classes='label--w-30'
          />
          <div>
            <CustomButton
              type='button'
              classes='btn--w-200-px btn--black'
              title='Export'
              endIcon={<img src={DownloadIcon} alt='Download report' />}
              handleClick={handleDataExport}
            />
          </div>
        </div>
        <div className='report__grid ag-theme-balham'>
          <AgGridReact
            rowHeight={30}
            loading={isLoading}
            defaultColDef={{
              resizable: true,
              flex: 1,
              minWidth: 200,
              filter: 'agTextColumnFilter'
            }}
            enableCellTextSelection
            pagination
            rowData={rowData}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            rowGroupPanelShow='always'
            suppressDragLeaveHidesColumns
            excelStyles={[
              {
                id: 'stringType',
                dataType: 'String'
              }
            ]}
          />
        </div>
      </StyledReport>
    </>
  );
};

export default StockAlignmentReporting;
