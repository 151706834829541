import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';

import {
  dateFormatter,
  dateHourFormatter,
  hourFormatter
} from '../../../../utils';
import { IDeliveryStatus } from '../../../../interfaces/IOrder';
import StyledDeliveryPipeline from './styles';
import CustomSwitch from '../../../../components/CustomSwitch';

const DeliveryPipeline: React.FC<{ history: IDeliveryStatus[] }> = ({
  history
}) => {
  const [gridMode, setGridMode] = useState<boolean>(false);

  return (
    <StyledDeliveryPipeline>
      <h5 className='inner-accordion__details__subtitle'>Delivery pipeline</h5>
      <CustomSwitch
        checked={gridMode}
        handleChange={(event: any) => setGridMode(event.target.checked)}
        onLabel='GRID'
        offLabel='PIPELINE'
        className='label--dark'
      />
      {gridMode ? (
        <div className='delivery-grid ag-theme-balham'>
          <AgGridReact
            rowHeight={30}
            defaultColDef={{
              resizable: true,
              flex: 1,
              minWidth: 100,
              filter: 'agTextColumnFilter'
            }}
            enableCellTextSelection
            pagination
            rowData={history}
            columnDefs={[
              { headerName: 'Status', field: 'description', minWidth: 500 },
              {
                field: 'date',
                cellRenderer: (params: any) => dateHourFormatter(params.value)
              }
            ]}
            suppressDragLeaveHidesColumns
          />
        </div>
      ) : (
        history.map((delivery: IDeliveryStatus) => (
          <div key={delivery.date} className='delivery-pipeline'>
            <div className='delivery-pipeline__status'>
              <div className='delivery-pipeline__status__circle'>
                {delivery.description}
              </div>
              <div className='delivery-pipeline__status__circle--sm' />
              <hr />
              <div className='delivery-pipeline__status__circle--md' />
              <div className='delivery-pipeline__status__date'>
                <p>{dateFormatter(delivery.date)}</p>
                <p>{hourFormatter(delivery.date)}</p>
              </div>
            </div>
            <div className='delivery-pipeline__vl' />
          </div>
        ))
      )}
    </StyledDeliveryPipeline>
  );
};

export default DeliveryPipeline;
